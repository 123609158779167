import { useCallback, useEffect, useState } from 'react';
import { useQuery, useMutation, QueryHookOptions } from 'react-apollo';
import { ApolloError, FetchPolicy, WatchQueryFetchPolicy } from 'apollo-client';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  BotStatusDataQuery,
  BotStatusDataQueryVariables,
} from '@utils/Bot/@types/BotStatusDataQuery';
import { BotStatus, BOT_STATUS_DATA_QUERY, getBotStatus } from '@utils/Bot';
import { downloadInvoice } from '@utils/downloadInvoice';
import { useCurrentBotId } from '@utils/Routing';
import { getTierNameByType } from '@components/DialogsPricing/constants';
import { assert } from '@utils/Assert';
import { useToaster } from '@ui/Toaster';
import { ChargingEntityType, ChurnSurveyAdviceType, TierType } from '@globals';

import {
  DIALOGS_PRICING_QUERY,
  PREMIUM_PRICING_INFO_QUERY,
  PRICING_PLAN_TIERS_QUERY,
  BILLING_INFO_QUERY,
  PAYMENT_INFO_QUERY,
  PAYMENT_HISTORY_QUERY,
  GET_INVOICE_QUERY,
  PRO_PLAN_UNPAUSE_MUTATION,
  GET_CONFIGURE_TAB_BOT_DATA,
  HAS_PAYMENT_DEBT_QUERY,
  PAUSE_PRICING_QUERY,
  PRICE_PERIOD_LIST_QUERY,
  PREMIUM_PRICING_PLAN_TIERS_QUERY,
  PREMIUM_TIERS_PRICE_PERIOD_LIST_QUERY,
  PREMIUM_PRICING_PAYMENT_LIST_QUERY,
  WORKSPACES_BILLING_INFO_QUERY,
  PREMIUM_PRICING_INVOICE_QUERY,
  PREMIUM_PRICING_USAGE_QUERY,
  BILLING_PAYMENT_HISTORY_QUERY,
  CHURN_SURVEY_ADVICE_QUERY,
  TRIAL_STATS_QUERY,
} from './queries';
import {
  CHURN_SURVEY_BUILD_BOT_MUTATION,
  SWITCH_PREMIUM_PRICING_TIER_MUTATION,
  SWITCH_PRICING_TIER_MUTATION,
  UPDATE_WORKSPACES_BILLING_INFO_MUTATION,
} from './mutations';
import { log } from 'cf-common/src/logger';
import {
  DialogsPricingQuery,
  DialogsPricingQueryVariables,
} from './@types/DialogsPricingQuery';
import {
  PricingPlanTiersQuery,
  PricingPlanTiersQueryVariables,
} from './@types/PricingPlanTiersQuery';
import { PremiumPricingPlanTiersQuery } from './@types/PremiumPricingPlanTiersQuery';
import {
  BillingInfoQuery,
  BillingInfoQueryVariables,
} from './@types/BillingInfoQuery';
import {
  PaymentInfoQuery,
  PaymentInfoQueryVariables,
} from './@types/PaymentInfoQuery';
import {
  PaymentHistoryQuery,
  PaymentHistoryQueryVariables,
} from './@types/PaymentHistoryQuery';
import {
  GetInvoiceQuery,
  GetInvoiceQueryVariables,
} from './@types/GetInvoiceQuery';
import {
  ProPlanUnpauseMutation,
  ProPlanUnpauseMutationVariables,
} from './@types/ProPlanUnpauseMutation';
import {
  HasPaymentDebtQuery,
  HasPaymentDebtQueryVariables,
} from './@types/HasPaymentDebtQuery';
import { useBotFeatures, useCurrentBot } from '../Bot';
import { calcPlanLimitReached } from './utils';
import { formatPrice } from '@utils/Format';
import {
  PausePricingQuery,
  PausePricingQueryVariables,
} from './@types/PausePricingQuery';
import Maybe from 'graphql/tsutils/Maybe';
import {
  PricePeriodListQuery,
  PricePeriodListQueryVariables,
} from './@types/PricePeriodListQuery';
import {
  PremiumTiersPricePeriodListQuery,
  PremiumTiersPricePeriodListQueryVariables,
} from './@types/PremiumTiersPricePeriodListQuery';
import {
  SwitchPricingTierMutation,
  SwitchPricingTierMutationVariables,
} from './@types/SwitchPricingTierMutation';
import {
  SwitchPremiumPricingTierMutation,
  SwitchPremiumPricingTierMutationVariables,
} from './@types/SwitchPremiumPricingTierMutation';
import { PremiumPricingInfoQuery } from './@types/PremiumPricingInfoQuery';
import {
  PremiumPricingPaymentListQuery,
  PremiumPricingPaymentListQueryVariables,
} from './@types/PremiumPricingPaymentListQuery';
import { WorkspacesBillingInfoQuery } from './@types/WorkspacesBillingInfoQuery';
import {
  UpdateWorkspacesBillingInfoMutation,
  UpdateWorkspacesBillingInfoMutationVariables,
} from './@types/UpdateWorkspacesBillingInfoMutation';
import {
  PremiumPricingInvoiceQuery,
  PremiumPricingInvoiceQueryVariables,
} from './@types/PremiumPricingInvoiceQuery';
import {
  PremiumPricingUsageQuery,
  PremiumPricingUsageQueryVariables,
} from './@types/PremiumPricingUsageQuery';
import client from '@common/services/ApolloService';
import {
  BillingPaymentHistoryQuery,
  BillingPaymentHistoryQueryVariables,
} from './@types/BillingPaymentHistoryQuery';
import { BOT_PROMO_CODE_QUERY } from '../PromoCode/queries';
import {
  ChurnSurveyAdviceQuery,
  ChurnSurveyAdviceQueryVariables,
  ChurnSurveyAdviceQuery_churnSurveyAdvice_data_ChurnSurveyAdviceDowngradePlan,
  ChurnSurveyAdviceQuery_churnSurveyAdvice_data_ChurnSurveyAdvicePauseBot,
  ChurnSurveyAdviceQuery_churnSurveyAdvice_data_ChurnSurveyAdviceRemoveUsers,
} from './@types/ChurnSurveyAdviceQuery';
import {
  ChurnSurveyBuildBotMutation,
  ChurnSurveyBuildBotMutationVariables,
} from './@types/ChurnSurveyBuildBotMutation';
import {
  TrialStatsQuery,
  TrialStatsQueryVariables,
} from './@types/TrialStatsQuery';
import { useWorkspace } from '../Workspaces/useWorkspace';
import { isBotPro, isBotProInWorkspace } from '@utils/Pro';

export const useDialoguesPricing = (
  options?: QueryHookOptions<DialogsPricingQuery, DialogsPricingQueryVariables>,
) => {
  const botId = useCurrentBotId()!;
  const {
    data: pricingData,
    loading: pricingLoading,
    error: pricingError,
    refetch: pricingRefetch,
  } = useQuery<DialogsPricingQuery, DialogsPricingQueryVariables>(
    DIALOGS_PRICING_QUERY,
    { variables: { botId }, skip: !botId || options?.skip, ...options },
  );
  return {
    pricing: pricingData?.bot.dialogsPricing,
    pageId: pricingData?.bot.status?.page,
    pricingLoading,
    pricingError,
    pricingRefetch,
  };
};

export const refetchDialogsPricingQuery = (botId: string) =>
  client.query<DialogsPricingQuery, DialogsPricingQueryVariables>({
    query: DIALOGS_PRICING_QUERY,
    variables: { botId },
    fetchPolicy: 'network-only',
  });

export const usePremiumPricingInfo = (
  options?: QueryHookOptions<PremiumPricingInfoQuery>,
) => {
  const { data, loading, error } = useQuery<PremiumPricingInfoQuery>(
    PREMIUM_PRICING_INFO_QUERY,
    options,
  );
  return { data: data?.premiumPricingInfo, loading, error };
};

export const useTiersData = (
  botId: Maybe<string>,
  pricingPlanId?: string | null,
  currency?: string | null,
  options?: QueryHookOptions<
    PricingPlanTiersQuery,
    PricingPlanTiersQueryVariables
  >,
) => {
  const { data: tiersData, loading: tiersLoading } = useQuery<
    PricingPlanTiersQuery,
    PricingPlanTiersQueryVariables
  >(PRICING_PLAN_TIERS_QUERY, {
    variables: {
      botId: botId || '',
      pricingPlanId: pricingPlanId!,
      currency: currency!,
    },
    skip: !currency || !pricingPlanId || !botId,
    ...options,
  });
  const tiers = tiersData?.bot.pricingPlanTiers ?? [];
  const getTier = (tierType: Maybe<string>) =>
    tiers.find(({ type }) => tierType === type);
  return {
    tiers,
    tiersLoading,
    getTier,
  };
};

export const usePremiumTiersData = () => {
  const { data, loading } = useQuery<PremiumPricingPlanTiersQuery>(
    PREMIUM_PRICING_PLAN_TIERS_QUERY,
  );
  return {
    data: data?.premiumPricingPlanTiers,
    loading,
  };
};

export const usePremiumPricingPeriodList = (tierType: TierType) => {
  const { data, loading, error } = useQuery<
    PremiumTiersPricePeriodListQuery,
    PremiumTiersPricePeriodListQueryVariables
  >(PREMIUM_TIERS_PRICE_PERIOD_LIST_QUERY, {
    variables: { tierType },
    skip: !tierType,
  });

  return {
    data: data?.premiumTiersPricePeriodList ?? [],
    loading,
    error,
  };
};

export const useBillingPaymentsHistory = () => {
  const { bot } = useCurrentBot();
  const { data, loading, error } = useQuery<
    BillingPaymentHistoryQuery,
    BillingPaymentHistoryQueryVariables
  >(BILLING_PAYMENT_HISTORY_QUERY, {
    variables: { subscriptionId: bot?.status?.page || '' },
    skip: !bot?.status?.page,
    fetchPolicy: 'network-only',
  });

  return {
    paymentsHistory: data?.premiumPricingPaymentList ?? [],
    paymentsHistoryLoading: loading,
    paymentsHistoryError: error,
  };
};

export const usePaymentsHistory = (fetchPolicy?: WatchQueryFetchPolicy) => {
  const botId = useCurrentBotId()!;
  const { data, loading, error } = useQuery<
    PaymentHistoryQuery,
    PaymentHistoryQueryVariables
  >(PAYMENT_HISTORY_QUERY, {
    variables: { botId },
    fetchPolicy: fetchPolicy ?? 'network-only',
  });

  return {
    paymentsHistory: data?.bot.paymentInformation.payments ?? [],
    paymentsHistoryLoading: loading,
    paymentsHistoryError: error,
  };
};

export const usePaymentInfo = (fetchPolicy?: WatchQueryFetchPolicy) => {
  const botId = useCurrentBotId()!;
  const { data: paymentInfoData, loading } = useQuery<
    PaymentInfoQuery,
    PaymentInfoQueryVariables
  >(PAYMENT_INFO_QUERY, {
    variables: { botId },
    skip: !botId,
    fetchPolicy: fetchPolicy ?? 'network-only',
  });
  return {
    paymentInfo: paymentInfoData?.bot.paymentInformation,
    paymentInfoLoading: loading,
  };
};

export const usePaymentError = (fetchPolicy?: WatchQueryFetchPolicy) => {
  const { paymentsHistory, paymentsHistoryLoading } =
    usePaymentsHistory(fetchPolicy);
  const lastFailedPayment = paymentsHistory[0];

  return {
    paymentsHistoryLoading,
    isPaymentFailed: !!lastFailedPayment?.stripeResult?.failed,
    amountToPay: lastFailedPayment?.amount ?? 0,
  };
};

export const useBillingInfo = () => {
  const botId = useCurrentBotId()!;
  const { data: billingInfoData, loading } = useQuery<
    BillingInfoQuery,
    BillingInfoQueryVariables
  >(BILLING_INFO_QUERY, {
    variables: { botId },
    skip: !botId,
  });
  return {
    billingInfo: billingInfoData?.bot.billingDetails,
    billingInfoLoading: loading,
  };
};

export const useDownloadInvoice = () => {
  const botId = useCurrentBotId()!;
  const [getInvoiceQuery, { data, loading }] = useLazyQuery<
    GetInvoiceQuery,
    GetInvoiceQueryVariables
  >(GET_INVOICE_QUERY, {
    fetchPolicy: 'no-cache',
  });
  const [date, setDate] = useState<string | null>(null);

  const getInvoice = useCallback(
    async (invoiceId: string, invoiceDate: string | null) => {
      setDate(invoiceDate);
      return getInvoiceQuery({
        variables: { id: invoiceId, botId },
      });
    },
    [getInvoiceQuery, botId, setDate],
  );

  useEffect(() => {
    if (data?.invoice && date) {
      downloadInvoice(date, data.invoice.html);
      setDate(null);
    }
  }, [data, date]);

  return { downloadInvoice: getInvoice, downloadInvoiceLoading: loading };
};

// TODO: refactor -> refetch less data
export const useProPlanUnpause = (botId: string) => {
  return useMutation<ProPlanUnpauseMutation, ProPlanUnpauseMutationVariables>(
    PRO_PLAN_UNPAUSE_MUTATION,
    {
      refetchQueries: [
        {
          query: GET_CONFIGURE_TAB_BOT_DATA,
          variables: { botId },
        },
      ],
    },
  );
};

export interface PlanLimitReachedData {
  nextPlanText: string;
  nextPlanType?: TierType;
  currentPlanText: string;
  currentPlanType?: string | null;
  nextPlanLimit?: number;
  botStatus?: BotStatus;
  pricingType: ChargingEntityType;
  dialogsPricingEnabled: boolean;
  planLimit: number;
  isLimitReached: boolean;
  botId: string;
  visible: boolean;
}

export const usePlanLimitReached = (
  options?: QueryHookOptions<BotStatusDataQuery, BotStatusDataQueryVariables>,
  ignoreTierCheck: boolean = false,
): PlanLimitReachedData | null => {
  const { t } = useSafeTranslation();
  const { bot } = useCurrentBot();
  const botId = useCurrentBotId();
  const { botFeatures } = useBotFeatures(botId ?? '');
  const dialogsPricingEnabled = Boolean(botFeatures?.dialogs_pricing_enabled);
  const { paymentsHistoryLoading, ...paymentError } =
    usePaymentError('cache-and-network');
  const { workspace } = useWorkspace(bot?.workspace_id);

  const { data: botStatusData, loading } = useQuery<
    BotStatusDataQuery,
    BotStatusDataQueryVariables
  >(BOT_STATUS_DATA_QUERY, {
    variables: { botId: botId! },
    skip: !botId,
    ...options,
  });
  const pricing = botStatusData?.bot.dialogsPricing;
  const { tiers: dialogsPricingTiers } = useTiersData(
    botId ?? '',
    pricing?.pricing_plan_id,
    pricing?.currency,
  );

  if (
    !botId ||
    !botFeatures ||
    !pricing ||
    bot?.workspace_id ||
    bot?.pro?.manual ||
    botStatusData?.bot?.id !== botId
  )
    return null;

  const isLimitReached = calcPlanLimitReached(pricing, loading);

  if (dialogsPricingEnabled) {
    if (TierType.trial !== pricing.current_tier && !ignoreTierCheck)
      return null;

    const pricingType = ChargingEntityType.dialogs;
    const currentPlanText = pricing.current_tier
      ? getTierNameByType(pricing.current_tier)
      : '';
    const planLimit = pricing.current_tier_limit ?? 0;
    const currentPlanIndex = dialogsPricingTiers.findIndex(
      ({ type }) => pricing.current_tier === type,
    );
    const nextPlanIndex = currentPlanIndex + 1;
    const nextPlanData =
      currentPlanIndex === -1 || nextPlanIndex === dialogsPricingTiers.length
        ? null
        : dialogsPricingTiers[nextPlanIndex];
    const nextPlanText = nextPlanData?.type
      ? getTierNameByType(nextPlanData.type)
      : '';
    const nextPlanLimit = nextPlanData?.tier_dialogs;

    assert(pricing.current_tier, { msg: 'Current tier should be defined' });

    return {
      nextPlanText,
      nextPlanType: nextPlanData?.type,
      currentPlanText,
      currentPlanType: pricing.current_tier,
      nextPlanLimit,
      pricingType,
      dialogsPricingEnabled,
      planLimit,
      isLimitReached,
      botId,
      visible: Boolean(
        isLimitReached && nextPlanText && currentPlanText && botId,
      ),
    };
  }

  const status = getBotStatus(botStatusData.bot);

  const isPaymentFailed = Boolean(paymentError?.isPaymentFailed);
  const isPro =
    isBotPro(bot) || isBotProInWorkspace(bot, workspace || undefined);

  /**
   * There is a restriction only on the free plan, we select only free
   */
  if (status !== BotStatus.premium && (!isPro || isPaymentFailed)) {
    const isTrial = botStatusData.bot.pro?.status === 'trial';
    return {
      nextPlanText: t('UpgradePage-string-8052-pro'),
      currentPlanText: isTrial ? t('UpgradePage-string-8107-trial') : '',
      currentPlanType: isTrial ? TierType.trial : null,
      pricingType: ChargingEntityType.users,
      dialogsPricingEnabled,
      planLimit: pricing.current_tier_limit ?? 0,
      isLimitReached,
      botId,
      botStatus: status,
      visible: Boolean(isLimitReached && botId),
    };
  }

  return null;
};

export const useHasPaymentDebt = (botId?: string) => {
  const { data } = useQuery<HasPaymentDebtQuery, HasPaymentDebtQueryVariables>(
    HAS_PAYMENT_DEBT_QUERY,
    { variables: { botId: botId! } },
  );
  return {
    prepayment: Boolean(data?.bot.paymentInformation.has_prepayment_debt),
    adjustmentFee: Boolean(
      data?.bot.paymentInformation.has_adjustment_fee_debt,
    ),
  };
};

const getPausePrice = (
  pausePricing: Maybe<number[][]>,
  currency: Maybe<string>,
): string | undefined => {
  const [pausePriceTuple] = pausePricing ?? [];
  const [, pausePrice] = pausePriceTuple ?? [];

  if (pausePrice) return formatPrice(pausePrice, { currency, decimals: 0 });
  return undefined;
};

export const usePausePricing = (
  botId: Maybe<string>,
  currency: Maybe<string>,
) => {
  const { data, loading } = useQuery<
    PausePricingQuery,
    PausePricingQueryVariables
  >(PAUSE_PRICING_QUERY, { variables: { botId: botId! }, skip: !botId });

  return {
    pausePrice: getPausePrice(data?.bot.pausePricing, currency),
    loading,
  };
};

interface PricingPeriodListParams {
  botId: string | undefined;
  pricingPlanId: string | undefined;
  currency: string | undefined;
  tierType: TierType | undefined;
  fetchPolicy?: FetchPolicy;
}

export const usePricingPeriodList = ({
  botId,
  pricingPlanId,
  currency,
  tierType,
  fetchPolicy,
}: PricingPeriodListParams) => {
  const { data, loading, error } = useQuery<
    PricePeriodListQuery,
    PricePeriodListQueryVariables
  >(PRICE_PERIOD_LIST_QUERY, {
    variables: {
      botId: botId!,
      pricingPlanId: pricingPlanId!,
      currency: currency!,
      tierType: tierType!,
    },
    skip: !botId || !pricingPlanId || !currency || !tierType,
    fetchPolicy,
  });

  return {
    data: data?.bot.pricePeriodList ?? [],
    loading,
    error,
  };
};

interface SwitchPricingTierMutationConfig {
  botId: string;
  onSwitchingCompleted?(payload: SwitchPricingTierMutation): void;
}

export const useSwitchPricingTierMutation = ({
  botId,
  onSwitchingCompleted,
}: SwitchPricingTierMutationConfig) => {
  const { addToaster } = useToaster();
  const { t } = useSafeTranslation();

  const handleMutationError = useCallback(
    (error: ApolloError) => {
      log.error({ error, data: { label: 'switch pricing tier' } });
      addToaster({
        type: 'error',
        content: t('common.ToasterMessages.somethingWentWrong'),
        timeout: 5000,
        closeButton: true,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [switchPricingTierMutation, { loading: switchingTier }] = useMutation<
    SwitchPricingTierMutation,
    SwitchPricingTierMutationVariables
  >(SWITCH_PRICING_TIER_MUTATION, {
    onCompleted: onSwitchingCompleted,
    onError(error) {
      handleMutationError(error);
    },
    refetchQueries: [
      {
        query: BOT_PROMO_CODE_QUERY,
        variables: { botId },
      },
    ],
  });

  return {
    switchPricingTierMutation,
    switchingTier,
    handleMutationError,
  };
};

interface SwitchPremiumPricingTierMutationConfig {
  onSwitchingCompleted(payload: SwitchPremiumPricingTierMutation): void;
}

export const useSwitchPremiumPricingTierMutation = ({
  onSwitchingCompleted,
}: SwitchPremiumPricingTierMutationConfig) => {
  const { addToaster } = useToaster();
  const { t } = useSafeTranslation();

  const handleMutationError = useCallback(
    (error: ApolloError) => {
      log.error({ error, data: { label: 'switch pricing tier' } });
      addToaster({
        type: 'error',
        content: t('common.ToasterMessages.somethingWentWrong'),
        timeout: 5000,
        closeButton: true,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [switchPricingTierMutation, { loading: switchingTier }] = useMutation<
    SwitchPremiumPricingTierMutation,
    SwitchPremiumPricingTierMutationVariables
  >(SWITCH_PREMIUM_PRICING_TIER_MUTATION, {
    onCompleted: onSwitchingCompleted,
    onError(error) {
      handleMutationError(error);
    },
  });

  return {
    switchPricingTierMutation,
    switchingTier,
    handleMutationError,
  };
};

export const usePremiumPricingPaymentList = (subscriptionId: Maybe<string>) => {
  const { data, loading } = useQuery<
    PremiumPricingPaymentListQuery,
    PremiumPricingPaymentListQueryVariables
  >(PREMIUM_PRICING_PAYMENT_LIST_QUERY, {
    variables: {
      subscriptionId: subscriptionId!,
    },
    skip: !subscriptionId,
  });

  return [data?.premiumPricingPaymentList, loading] as const;
};

export const useWorkspacesBillingInfo = () => {
  const { data, loading } = useQuery<WorkspacesBillingInfoQuery>(
    WORKSPACES_BILLING_INFO_QUERY,
  );

  return [data?.workspacesBillingDetails, loading] as const;
};

export const useUpdateWorkspacesBillingInfo = () => {
  return useMutation<
    UpdateWorkspacesBillingInfoMutation,
    UpdateWorkspacesBillingInfoMutationVariables
  >(UPDATE_WORKSPACES_BILLING_INFO_MUTATION);
};

export const useDownloadPremiumPricingInvoice = () => {
  const [fetchData, { data, loading: queryLoading }] = useLazyQuery<
    PremiumPricingInvoiceQuery,
    PremiumPricingInvoiceQueryVariables
  >(PREMIUM_PRICING_INVOICE_QUERY);
  const [date, setDate] = useState<string | null>(null);

  useEffect(() => {
    if (data && date && !queryLoading) {
      downloadInvoice(date, data.premiumPricingInvoice.html);
      setDate(null);
    }
  }, [data, date, setDate, queryLoading]);

  const loading = queryLoading || Boolean(date);

  const getInvoice = useCallback(
    async (subscriptionId: string, invoiceId: string, invoiceDate: string) => {
      setDate(invoiceDate);
      return fetchData({
        variables: {
          subscriptionId,
          invoiceId,
        },
      });
    },
    [setDate, fetchData],
  );

  return [getInvoice, loading] as const;
};

export const usePremiumPricingUsage = (
  subscriptionId?: string | null,
  onCompleted?: (data: PremiumPricingUsageQuery) => void,
) => {
  const [query, { data, loading }] = useLazyQuery<
    PremiumPricingUsageQuery,
    PremiumPricingUsageQueryVariables
  >(PREMIUM_PRICING_USAGE_QUERY, { onCompleted });

  const getPremiumPricingUsage = useCallback(
    async (year?: number) => {
      if (subscriptionId) {
        query({ variables: { subscriptionId, year } });
      }
    },
    [query, subscriptionId],
  );

  useEffect(() => {
    getPremiumPricingUsage();
  }, [getPremiumPricingUsage]);

  return {
    data: data?.premiumPricingUsage,
    loading,
    getPremiumPricingUsage,
  };
};

const mapChurnSurveyData = (data: Maybe<ChurnSurveyAdviceQuery>) => {
  return {
    churnSurveyAdviceType: data?.churnSurveyAdvice.advice,
    churnSurveyAdviceData: data?.churnSurveyAdvice.data,
  } as
    | {
        churnSurveyAdviceData: ChurnSurveyAdviceQuery_churnSurveyAdvice_data_ChurnSurveyAdviceDowngradePlan;
        churnSurveyAdviceType: ChurnSurveyAdviceType.downgrade_plan;
      }
    | {
        churnSurveyAdviceData: ChurnSurveyAdviceQuery_churnSurveyAdvice_data_ChurnSurveyAdvicePauseBot;
        churnSurveyAdviceType: ChurnSurveyAdviceType.pause_bot;
      }
    | {
        churnSurveyAdviceData: ChurnSurveyAdviceQuery_churnSurveyAdvice_data_ChurnSurveyAdviceRemoveUsers;
        churnSurveyAdviceType: ChurnSurveyAdviceType.remove_users;
      }
    | {
        churnSurveyAdviceData: null;
        churnSurveyAdviceType: ChurnSurveyAdviceType.improve_bot;
      };
};

export const useChurnSurveyAdvice = () => {
  const botId = useCurrentBotId();

  const { data, loading, ...queryData } = useQuery<
    ChurnSurveyAdviceQuery,
    ChurnSurveyAdviceQueryVariables
  >(CHURN_SURVEY_ADVICE_QUERY, { variables: { botId: botId! }, skip: !botId });

  return {
    ...mapChurnSurveyData(data),
    churnSurveyAdviceLoading: loading,
    ...queryData,
  };
};

export const useChurnSurveyBuildBot = () => {
  const botId = useCurrentBotId();
  const [buildBot] = useMutation<
    ChurnSurveyBuildBotMutation,
    ChurnSurveyBuildBotMutationVariables
  >(CHURN_SURVEY_BUILD_BOT_MUTATION, { variables: { botId: botId! } });

  return { buildBot };
};

export const useTrialStatsQuery = (
  options?: QueryHookOptions<TrialStatsQuery, TrialStatsQueryVariables>,
) => {
  const { data, loading: trialStatsLoading } = useQuery<
    TrialStatsQuery,
    TrialStatsQueryVariables
  >(TRIAL_STATS_QUERY, options);

  return { trialStatsData: data, trialStatsLoading };
};

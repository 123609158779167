import { log } from 'cf-common/src/logger';

const logCallingBeforeInitError = () =>
  log.error({
    msg: 'initial_web_app_params calling get before init',
  });

class InitialWebAppParams {
  private searchParams?: URLSearchParams;

  public init() {
    this.searchParams = new URLSearchParams(window.location.search);
  }

  public get(name: string): string | null {
    if (!this.searchParams) {
      logCallingBeforeInitError();
      return null;
    }

    return this.searchParams.get(name);
  }

  public has(name: string): boolean {
    if (!this.searchParams) {
      logCallingBeforeInitError();
      return false;
    }

    return this.searchParams.has(name);
  }
}

export type { InitialWebAppParams };

export const initialWebAppParams = new InitialWebAppParams();

export const collectInitialWebAppParams = () => {
  initialWebAppParams.init();

  return initialWebAppParams;
};

import { log } from 'cf-common/src/logger';
import type { InitialWebAppParams } from './initialWebAppParams';

const SWITCH_FROM_AWESOME_PANEL_QUERY_PARAM = 'force-auth-by-switch-from-panel';
const SWITCH_FROM_AWESOME_PANEL_SESSION_KEY = '_cf_switch_from_awesome_panel';
export const SWITCH_FROM_AWESOME_PANEL_API_QUERY_PARAM_NAME =
  'force_auth_from_cf_products_switcher';

let collected = false;
/**
 * Сохраняю ФЛАГ свитча из нового продукта
 */
export const collectSwitchFromAwesomePanelFlag = (
  initialWebAppParams: InitialWebAppParams,
) => {
  if (initialWebAppParams.has(SWITCH_FROM_AWESOME_PANEL_QUERY_PARAM)) {
    window.sessionStorage.setItem(
      SWITCH_FROM_AWESOME_PANEL_SESSION_KEY,
      'true',
    );
  }
  collected = true;
};

export const isSwitchFromAwesomePanelSession = () => {
  if (!collected)
    log.error({
      error: `Using a flag ${SWITCH_FROM_AWESOME_PANEL_SESSION_KEY} before saving it`,
    });

  return !!window.sessionStorage.getItem(SWITCH_FROM_AWESOME_PANEL_SESSION_KEY);
};
